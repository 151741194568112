import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/store';

Vue.use(VueRouter);

const HomePage = () => import(/* webpackChunkName: "home_page" */ '@/views/home.vue');
const SearchPage = () => import(/* webpackChunkName: "search_page" */ '@/views/search.vue');
const ContactUsPage = () => import(/* webpackChunkName: "contact_us" */ '@/views/contact_us.vue');
const SupportPage = () => import(/* webpackChunkName: "support" */ '@/views/support.vue');
const ResetPasswordPage = () => import(/* webpackChunkName: "reset_password" */ '@/views/reset-password.vue');
const ConsultantPublic = () => import(/* webpackChunkName: "consultant_profile_public" */ '@/views/consultant-pages/profile-public.vue');
const Consultant = () => import(/* webpackChunkName: "consultant_profile_private" */ '@/views/consultant-pages/profile-private.vue');
const ConsultantPrivateProfile = () => import(/* webpackChunkName: "consultant_summary" */ '@/views/consultant-pages/summary.vue');
const ConsultantMessages = () => import(/* webpackChunkName: "consultant_messages" */ '@/views/consultant-pages/messages.vue');
const ConsultantInfo = () => import(/* webpackChunkName: "consultant_profile" */ '@/views/consultant-pages/my-profile.vue');
const Booking = () => import(/* webpackChunkName: "booking" */ '@/views/consultant-pages/booking.vue');
const UserProfilePrivate = () => import(/* webpackChunkName: "user_profile_private" */ '@/views/user-pages/profile-private.vue');
const UserSummary = () => import(/* webpackChunkName: "user_summary" */ '@/views/user-pages/summary.vue');
const UserMessages = () => import(/* webpackChunkName: "user_messages" */ '@/views/user-pages/messages.vue');
const UserUpdate = () => import(/* webpackChunkName: "user_profile" */ '@/views/user-pages/my-profile.vue');
const Forbidden = () => import(/* webpackChunkName: "forbidden" */ '@/views/forbidden.vue');
const NotFound = () => import(/* webpackChunkName: "forbidden" */ '@/views/404.vue');
const routes = [
  {
    path: '/login',
    name: 'login_page',
    component: HomePage,
    meta: { noFooter: true },
  },
  {
    path: '/',
    name: 'home_page',
    component: HomePage,
    meta: { noFooter: true },
  },
  {
    path: '/search',
    name: 'search_page',
    component: SearchPage,
  },
  {
    path: '/contact-us',
    name: 'contact_us_page',
    component: ContactUsPage,
    meta: { noFooter: true },
  },
  {
    path: '/support',
    name: 'support_page',
    component: SupportPage,
  },
  {
    path: '/reset-password/:id',
    name: 'reset_password_page',
    component: ResetPasswordPage,
    meta: { noFooter: true },
  },
  {
    path: '/consultant/public/:id',
    name: 'consultant_public',
    component: ConsultantPublic,
    meta: { requiresAuth: true, roles: ['consultant', 'client'] },
  },
  {
    path: '/consultant',
    component: Consultant,
    meta: { requiresAuth: true, roles: ['consultant'] },
    children: [
      {
        path: '',
        name: 'consultant_private_profile',
        component: ConsultantPrivateProfile,
        meta: { requiresAuth: true, roles: ['consultant'] },
      },
      {
        path: 'messages',
        name: 'consultant_messages',
        component: ConsultantMessages,
        meta: { requiresAuth: true, roles: ['consultant'] },
      },
      {
        path: 'update',
        name: 'consultant_update',
        component: ConsultantInfo,
        meta: { requiresAuth: true, roles: ['consultant'] },
      },
    ],
  },
  {
    name: 'booking',
    path: '/booking/:id',
    component: Booking,
    meta: { requiresAuth: true, roles: ['client'] },
  },
  {
    path: '/user/private/:id',
    component: UserProfilePrivate,
    children: [
      {
        path: '',
        name: 'user_private_profile',
        component: UserSummary,
        meta: { requiresAuth: true, roles: ['client'] },
      },
      {
        path: 'messages',
        name: 'user_messages',
        component: UserMessages,
        meta: { requiresAuth: true, roles: ['client'] },
      },
      {
        path: 'update',
        name: 'user_update',
        component: UserUpdate,
        meta: { requiresAuth: true, roles: ['client'] },
      },
    ],
  },
  {
    path: '/forbidden',
    name: 'forbidden_page',
    component: Forbidden,
    meta: { noFooter: true },
  },
  {
    path: '/404',
    name: '404_page',
    component: NotFound,
    meta: { noFooter: true },
  },
  { path: '*', redirect: '/' }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (store.state.user != null) {
      if (to.meta.roles.find((role) => role === store.state.role)) {
        next();
      } else {
        next({ to: 'forbidden_page' });
      }
    } else {
      next({ to: 'login_page' });
    }
  } else {
    next();
  }
});

export default router;
