<template>
  <div id="app">
    <app-header :imageUrl="dpUrl" @update:dpUrl="dpUrlChanged" v-if="headerOn" />
    <router-view @update:dpUrl="dpUrlChanged"></router-view>
    <app-footer v-if="footerOn" />
  </div>
</template>

<script>
import header from '@/views/common/header.vue';
import footer from '@/views/common/footer.vue';

export default {
  name: 'App',
  components: {
    'app-header': header,
    'app-footer': footer,
  },
  data() {
    return {
      dpUrl: null,
      selfConsultant: null,
      headerOn: true,
      footerOn: true,
    };
  },
  methods: {
    dpUrlChanged(url) {
      this.dpUrl = url;
    },
  },
};
</script>
